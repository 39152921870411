@import './mixin';

@include keyframes(flareAnimation) {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@include keyframes(fadeInDown) {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(fadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include keyframes(fadeInUp) {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
