@import 'src/style/base/mixin';

.preloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;

  &__spinner {
    position: absolute;
    width: 50px;
    height: 50px;
    @include animation('preloader 1s linear infinite');
  }
}

@include keyframes(preloader) {
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}