@import 'src/style/base/theme';

.wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
  overflow-y: hidden;
}
